import React, { useState } from 'react'

import { Typography, styled, Stack } from '@deliveryhero/armor'
import { DatePicker } from '@deliveryhero/armor-datepicker'

import { onFormPropertyChangeType } from '../../../components/LandingPageRenderer'
import { generateRangeToNumber, subtractYearsFromDate } from '../utils'

const Label = styled.label`
  color: #fff;
  margin-bottom: 16px;
`

const ErrorBox = styled.section`
  background-color: #d73d45;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
`

export const AgeFormSelector: React.FC<{
  minimumAge: number
  text: string
  underAgeError: string
  onChange: onFormPropertyChangeType
}> = ({ minimumAge, text, underAgeError, onChange }) => {
  const [showError, setShowError] = useState(false)

  const minimumDate = subtractYearsFromDate(minimumAge)
  const yearRange = generateRangeToNumber(minimumDate.getFullYear())

  const onAgeChange = (birthDate: Date) => {
    birthDate.setHours(0, 0, 0)

    const isUnderAge = birthDate > minimumDate
    setShowError(isUnderAge)
    onChange({ name: 'age', value: !isUnderAge })
  }

  return (
    <Stack>
      <Label>{text}</Label>
      <DatePicker
        yearRange={yearRange}
        inputMaskEnabled
        onDateValueChange={onAgeChange}
        enableCloseOnSelect
      />
      {showError && (
        <ErrorBox data-testid="error-box">
          <Typography paragraph small color="#fff" marginBottom={0}>
            {underAgeError}
          </Typography>
        </ErrorBox>
      )}
    </Stack>
  )
}
