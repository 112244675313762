import React from 'react'

import { validVideoProvider, videoEmbedSrc } from '../../../utils/video'

export const VideoPlayer = ({ url }) => {
  if (validVideoProvider(url)) {
    return (
      <iframe
        width="100%"
        height="100%"
        src={videoEmbedSrc(url)}
        title="Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
        style={{ position: 'absolute' }}
      />
    )
  }
  return (
    <video src={url} width="100%">
      <track default kind="captions" />
    </video>
  )
}
