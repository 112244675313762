import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dropdown,
  Grid,
  Radio,
  RadioGroup,
  TextInput,
  Typography,
  styled,
  Checkbox,
  Button,
} from '@deliveryhero/armor'

import { TurnstileCaptcha } from '../../components/Atoms/TurnstileCaptcha'
import { onFormPropertyChangeType } from '../../components/LandingPageRenderer'
import { config } from '../../config/global'
import { Field } from '../../global/types'

import { AgeFormSelector } from './form-components/AgeFormSelector'
import { PhoneNumberInput } from './form-components/PhoneNumberInput'
import { DenormalizedLandingPage } from './selectors'
import { convertRulesToCities } from './utils'

const VerticalGrid = styled(Grid)`
  flex-direction: column;

  & > * {
    margin-bottom: 36px;
  }
`

const FormTitle = styled(Typography)`
  color: #fff;
  margin-bottom: 60px;
  font-size 25px;
`

const SubmitButton = styled(Button)<{ background: string }>`
  background-color: ${props => props.background};
  color: ${props => props.color};
  width: 100%;
`

const Section = styled('section')`
  width: 100%;
`

const MultilineCheckbox = styled(Checkbox)`
  align-items: flex-start;
  & .Checkbox-Checkmark {
    margin-top: 4px;
    min-width: 16px;
  }
  & .Checkbox-Label {
    padding-left: 0;
    padding-inline-start: 8px;
  }
`

const handleDropdown = (e: React.ChangeEvent<HTMLInputElement>) => ({
  name: e.target.name,
  value: e.target.value,
})

const RadioComponent: React.FC<{
  t: TFunction
  field: Field
  onChange: onFormPropertyChangeType
  data: Record<string, string>
  index: number
}> = ({ t, field, onChange, data, index }) =>
  field.name === 'age' && field.settings.datePicker ? (
    <AgeFormSelector
      key={field.id}
      minimumAge={parseInt(field.text, 10)}
      text={t('FormGenerator.PleaseEnterBirthdate')}
      underAgeError={t('LandingPage.YouCannotApplyIfUnderage', {
        age: field.text,
      })}
      onChange={onChange}
    />
  ) : (
    <section key={field.id}>
      <Typography subSectionTitle color="#fff">
        {field.name === 'age'
          ? t('FormGenerator.AreYouOver').replace('$AGE', field.text)
          : field.text}
      </Typography>

      <RadioGroup
        name={field.name}
        selectedValue={data[field.name]}
        onChange={e => onChange(handleDropdown(e))}
        autoFocus={index === 0}
        typographyProps={{ large: false, color: '#fff !important' }}
      >
        {field.settings.options.map(option => (
          <Radio key={option.label} {...option} label={t(option.label)} />
        ))}
      </RadioGroup>
    </section>
  )

const mapping = {
  string: (
    field: Field,
    index: number,
    data: Record<string, string>,
    onChange: onFormPropertyChangeType,
  ) => (
    <TextInput
      style={{ background: 'white', width: '100%' }}
      key={field.id}
      name={field.name}
      label={field.text}
      value={data[field.name]}
      onChange={e => onChange(handleDropdown(e))}
      autoFocus={index === 0}
    />
  ),
  radio: (
    field: Field,
    index: number,
    data: Record<string, string>,
    onChange: onFormPropertyChangeType,
    t: TFunction,
  ) => (
    <RadioComponent
      t={t}
      field={field}
      onChange={onChange}
      data={data}
      index={index}
    />
  ),
}

export const FormGenerator: React.FC<{
  landingPage: DenormalizedLandingPage
  activeLandingPageContentIdx: number
  data: Record<string, string>
  onChange: onFormPropertyChangeType
  onSubmit: (data: Record<string, string>) => void
  previewMode: boolean
  formRef: React.RefObject<HTMLFormElement>
}> = ({
  landingPage,
  activeLandingPageContentIdx,
  data = {},
  onChange,
  onSubmit,
  previewMode,
  formRef,
}) => {
  const [t] = useTranslation()
  const [turnstileToken, setTurnstileToken] = React.useState('')

  const landingPageContent =
    landingPage.landingPageContents[activeLandingPageContentIdx]

  if (!landingPageContent.workflow) return null

  const cities = convertRulesToCities(landingPageContent.workflow.rules).map(
    city => ({
      value: city,
      label: city,
    }),
  )

  const vehicles = data.city
    ? landingPageContent.vehicles
        .filter(vehicle => vehicle.enabled)
        .filter(vehicle => vehicle.cities.includes(data.city))
        .map(vehicle => ({ value: vehicle.name, label: vehicle.text }))
    : []

  const fields = landingPageContent.fields
    .filter(field => field.enabled)
    .sort((a, b) => a.orderIndex - b.orderIndex)
    .map((field, index) => {
      if (field.name === 'phone_number') {
        return (
          <PhoneNumberInput
            countryCode={landingPage.country.id}
            field={field}
            index={index}
            onChange={onChange}
          />
        )
      }
      return mapping[field.fieldType](field, index, data, onChange, t)
    })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (onSubmit) {
      onSubmit({ ...data, turnstileToken })
    }
  }

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <VerticalGrid>
        <FormTitle sectionTitle>{t('LandingPage.CreateYourProfile')}</FormTitle>
        <Dropdown
          style={{ background: 'white', width: '100%' }}
          name="city"
          placeholder={t('LandingPage.ChooseYourCity')}
          value={data.city}
          options={cities}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(handleDropdown(e))
          }
        />
        <Dropdown
          name="vehicle"
          style={{ background: 'white', width: '100%' }}
          placeholder={t('LandingPage.ChooseYourVehicle')}
          disabled={!data.city}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(handleDropdown(e))
          }
          value={data.vehicle}
          options={vehicles}
        />

        {data.vehicle && (
          <>
            {fields}

            {landingPage.privacyPolicyUrl &&
              landingPage.privacyPolicyUrl !== '' && (
                <Section>
                  <MultilineCheckbox
                    name="policy-check"
                    value={data.privacyPolicyChecked}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement & HTMLLabelElement>,
                    ) =>
                      onChange({
                        name: 'privacyPolicyChecked',
                        value: e.target.checked,
                      })
                    }
                    // @ts-expect-error Armor only accept string as labels
                    label={
                      <Typography paragraph medium color="#fff">
                        {t('LandingPage.PrivacyPolicyText')}

                        <a
                          rel="noreferrer noopener"
                          target="_blank"
                          href={landingPage.privacyPolicyUrl}
                        >
                          <Typography paragraph medium color="#fff">
                            {t('LandingPage.PrivacyPolicyTextLink')}
                          </Typography>
                        </a>
                      </Typography>
                    }
                  />
                </Section>
              )}
          </>
        )}
        <TurnstileCaptcha
          sitekey={config.turnstileSiteKey}
          onTokenObtained={setTurnstileToken}
        />
        <SubmitButton
          data-testid="form-main-submit"
          type="submit"
          disabled={
            previewMode ||
            (landingPage.privacyPolicyUrl && !data.privacyPolicyChecked)
          }
          tertiary
          color={landingPage.theme.primaryColor}
          background={landingPage.theme.secondaryColor}
        >
          {t('LandingPage.Submit')}
        </SubmitButton>
      </VerticalGrid>
    </form>
  )
}
