import React from 'react'
import { v4 } from 'uuid'

import { Flex, FlexItem } from '@deliveryhero/armor'

import { Theme } from '../../../global/types'

/**
 *
 * @param Component : Any react component
 * @param tools: A list of tools to show in the hover toolbar
 * @param theme: Theme to be used
 * @returns A similar react component that shows a toolbar on hover
 *
 * The pupose of the higher-order function is to simplify the construction
 * of editable versions of existing components.
 *
 * Why do we need this when we can have something like a PopOver wrapper?
 *
 * Because it allows us to create polymorphic components.
 *
 * Eg:
 * ```typescript
 * const Div = () => <div />
 * const EditableDiv = editable(Div, [<span data-testid="tool" />])
 * const Poly = editable? Div:EditableDiv
 * .
 * .
 * .
 * <Poly prop1="xyx" />
 * ```
 */
export default function editable(
  Component: React.ComponentType,
  tools: Array<JSX.Element>,
  theme?: Theme,
) {
  return ({ children, ...props }) => {
    const [hovering, setHovering] = React.useState(false)
    return (
      <div
        data-testid={props['data-testid']}
        onMouseOver={e => {
          e.stopPropagation()
          setHovering(true)
        }}
        onFocus={e => {
          e.stopPropagation()
          setHovering(true)
        }}
        onMouseLeave={e => {
          e.stopPropagation()
          setHovering(false)
        }}
        style={{
          position: 'relative',
          border: `thin dashed ${theme?.secondaryColor || 'black'}`,
          borderRadius: '5px',
        }}
      >
        {hovering && (
          <span
            data-testid="editable-hover-toolbar"
            style={{
              zIndex: 1000,
              position: 'absolute',
              height: '100%',
              width: '100%',
              background: theme?.primaryColor || 'white',
              borderRadius: '5px',
              cursor: 'move',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Flex>
              {tools.map((tool: JSX.Element) => (
                <FlexItem key={v4()}>{tool}</FlexItem>
              ))}
            </Flex>
          </span>
        )}
        <Component {...props}>{children}</Component>
      </div>
    )
  }
}
