import { withPrefix } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import { Theme } from '../global/types'

export enum BLOCK_TYPES {
  LAYOUT_BLOCK = 'layout',
  TEXT_BLOCK = 'text',
  IMAGE_BLOCK = 'image',
  VIDEO_BLOCK = 'video',
  TEXT_LIST = 'list',
}

export enum EVENTS_TYPE {
  CLICK = 'click',
  MOUSE_OVER = 'mouseover',
}

export type Block = {
  id: string
  type: BLOCK_TYPES
  properties: Record<string, string | number> | undefined
  children?: Block[]
  value?: string
}

export type Image = Block & {
  file: File
  path?: string
}

type Mapping = {
  [key: string]: React.FunctionComponent
}

export type EventCallback = (
  type: EVENTS_TYPE,
  id: string,
  path: string | null,
  x: number,
  y: number,
) => void

const onCallbackTriggered = (callback: EventCallback) => e => {
  e.stopPropagation()
  callback(
    e.type as EVENTS_TYPE,
    e.target.attributes['data-id']?.value,
    e.target.attributes['data-path']?.value
      .replace('root.', '')
      ?.replace('root', ''),
    e.screenX,
    e.screenY,
  )
}

export const registerEvents = (callback: EventCallback) => {
  window.addEventListener(EVENTS_TYPE.CLICK, onCallbackTriggered(callback))
  window.addEventListener(EVENTS_TYPE.MOUSE_OVER, onCallbackTriggered(callback))
}

export const unregisterEvents = (callback: EventCallback) => {
  window.removeEventListener(EVENTS_TYPE.CLICK, onCallbackTriggered(callback))
  window.removeEventListener(
    EVENTS_TYPE.MOUSE_OVER,
    onCallbackTriggered(callback),
  )
}

// Support $PRIMARY_COLOR and $SECONDARY_COLOR
export const replaceThemeColors = (structure: Block, theme: Theme): Block =>
  JSON.parse(
    JSON.stringify(structure)
      .replace(/\$PRIMARY_COLOR/g, theme.primaryColor)
      .replace(/\$SECONDARY_COLOR/g, theme.secondaryColor),
  )

// Css formula for text resize:
// https://css-tricks.com/snippets/css/fluid-typography/
// We consider to be in a device until 600px
//
// NOTE: CSS hacks used to workaround current limitations
// 1. display: block !important;` is set for .renderer-image-block
//    This is workaround to fix the issue where the first image block
//    is hidden in mobile view. The normal way to do this would be
//    to edit DB data and change the landing page JSON and then re-publish the landing
//    page. This has to be done for every landing page in every country. Considering the
//    effort involved, we're deliberately choosing an easier workaround instead.
// 2. Positioning hacks for `@media only screen and (max-width: 375px)`
//    This was done to make sure the first image is displayed below the form.
//    Only LTR layour required this hack as RTL layout naturally
//    orders the columns correctly.
const rendererStyles = (theme: Theme) => `
  .renderer-image-block , .renderer-video-block {
    border-radius: 24px;
    display: block !important;
    width: 100%;
    align-self: center;
  }
  .renderer-layout-block .Grid-Root {
    width: 100%;
  }
  .renderer-layout-block .GridItem-Root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .GridItem-Root > img{
    margin: 0 auto;
  }
  .GridItem-Root > p{
    align-self: center;
  }
  .renderer-layout-block {
    max-width: 960px;
    margin: 0 auto;
    border-radius: 0.5rem;
  }
  @media only screen and (max-width: 960px) {
    .renderer-layout-block {
      flex-direction: column;
    }
  }
  @media only screen and (min-width: 600px) {
    .renderer-responsive-block {
      flex-direction: row;
    }
    h1 {
      font-size: calc(18px + (32 - 18) * ((100vw - 300px) / ( 1600 - 300 ))) ;
    }
    h3 {
      font-size: calc(14px + (20 - 14) * ((100vw - 300px) / ( 1600 - 300 )));
    }
  }
  .preview-mode :is(.renderer-layout-block, .renderer-text-block, .renderer-list-block, .renderer-image-block) {
    border: 1px dashed rgba(113,113,113,.5);
  }

  .media-edit-label {
    display: none;
  }

  .preview-mode:hover > .media-edit-label {
    display: block !important;
  }

  @media only screen and (min-width: 900px) {
    .landing-page-root {
      background: url('${withPrefix(theme.bgImageTop)}') right top no-repeat,
        url('${withPrefix(theme.bgImageRight)}') right center no-repeat,
        url('${withPrefix(theme.bgImageBottom)}') left bottom no-repeat,
        url('${withPrefix(theme.bgImageLeft)}') left center no-repeat, #fff;
      background-size: 30%;
      background-position-x: 100%, 100%, 0, 0%;
      background-position-y: 0, 72%, 100%, 50%;
    }
  }
  ${'' /* some hacks to workaround the styling limitation */}
  @media only screen and (max-width: 599px) {
    div[dir="ltr"] div[data-name="row1"] {
      flex-direction: column-reverse;
      padding-top: 25px !important;
    }

    :not(div[data-path="root.children.1"]) > .renderer-layout-block.GridItem-Root {
      width: 83%;
    }

    div[data-path="root.children.1.children.0"] {
      width: 83%;
    }
    
    div[data-path="root.children.1.children.1"] {
      width: 100%;
    }

    div[dir="ltr"] h2[data-path="root.children.1.children.0.children.0"] {
      position: absolute;
      top: 109px;
    }
    div[dir="ltr"] p[data-path="root.children.1.children.0.children.1"] {
      position: absolute;
      top: 202px;
    }
    div[dir="ltr"] div[data-path="root.children.1.children.1"] {
      margin-top: 140px;
      margin-bottom: 32px;
    }
  }
`

export const renderer = (
  previewMode: boolean,
  structureWithoutThemeValues: Block,
  mapping: Mapping = {},
  theme: Theme = {},
  childrenIndex = 'root',
  placeholders: Mapping = {},
) => {
  const desktopStyles = []
  const structure = replaceThemeColors(structureWithoutThemeValues, theme)
  const Comp = mapping[structure.type] || null
  if (!Comp) return null

  const isResponsiveClass =
    structure.type === 'layout' && structure.properties.responsive
      ? 'renderer-responsive-block'
      : ''

  const className = `${structure.className || ''} renderer-${
    structure.type || ''
  }-block ${isResponsiveClass} ${previewMode ? 'preview-mode' : ''} ${
    structure.type || ''
  }-${structure.id}`

  const children =
    structure.children?.map((block: Block, index: number) =>
      renderer(
        previewMode,
        block,
        mapping,
        theme,
        `${childrenIndex}.children.${index}`,
        placeholders,
      ),
    ) || []

  const dataId: Record<string, string> = {
    'data-name': structure?.name || '',
    'data-id': structure.id,
    'data-path': childrenIndex || '',
    className,
  }

  const actualValue = placeholders[structure.value] || structure.value || null

  if (structure.properties?.desktop && structure.properties.desktop.style) {
    desktopStyles.push(
      `
        .${structure.type || ''}-${structure.id} {
          ${Object.keys(structure.properties.desktop.style).reduce(
            (acc, curr) =>
              `${acc}\n${curr}: ${structure.properties.desktop.style[curr]}!important;`,
            '',
          )}
        }
      `,
    )
  }

  // Component type, props, children
  return (
    <>
      <Helmet>
        <style>{`${rendererStyles(theme)}`}</style>
        {Array.from(new Set(desktopStyles)).map(style => (
          <style>
            {`
          @media only screen and (min-width: 600px) {
            ${style}
          }`}
          </style>
        ))}
      </Helmet>
      {React.createElement(
        Comp,
        { ...structure.properties, ...dataId } || {},
        actualValue,
        ...children,
      )}{' '}
    </>
  )
}
