import { withPrefix } from 'gatsby'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

import { config } from '../config/global'
import { NavBar } from '../containers/navbar'
import { SEO } from '../global/types'

import './layout.css'

type LayoutProps = {
  children?: React.ReactNode
  headless?: boolean
  seo?: SEO
  mainClass?: string
  iconUrl?: string
}
export const Layout: FC<LayoutProps> = ({
  children,
  headless = false,
  seo,
  mainClass = '',
  iconUrl,
}) => (
  <>
    {seo && (
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <meta name="robots" content="index, follow" />
        {iconUrl && (
          <link rel="icon" type="image/png" href={withPrefix(iconUrl)} />
        )}
      </Helmet>
    )}
    {!headless && <NavBar />}
    <div className={`${mainClass} layout-container`}>
      <main>{children}</main>
      <footer />
    </div>
  </>
)
