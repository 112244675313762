import { withPrefix } from 'gatsby'
import React from 'react'
import { useImmerReducer } from 'use-immer'

import { Flex, FlexItem, Link, Typography } from '@deliveryhero/armor'

import { Theme } from '../../../global/types'

import AddColumn from './SubComponents/AddColumn'
import AddLink from './SubComponents/AddLink'
import DeleteColumn from './SubComponents/DeleteColumn'
import DeleteLink from './SubComponents/DeleteLink'
import EditColumn from './SubComponents/EditColumn'
import EditLink from './SubComponents/EditLink'
import {
  StyledColumn,
  StyledLink,
  FooterContainer,
  ColumnContainer,
} from './common.components'
import { FooterColumn } from './common.types'
import { footerDataReducer } from './dataLayer'
import editable from './editable'

type FooterProps = {
  editMode?: boolean
  data: FooterColumn[]
  theme: Theme
  onEdit?: (data: FooterColumn[]) => void
}

const Footer = ({ editMode = false, data, onEdit, theme }: FooterProps) => {
  const [columns, dispatch] = useImmerReducer(footerDataReducer, data)

  React.useEffect(() => {
    if (onEdit) onEdit(columns)
  }, [columns])

  return (
    <div
      style={{
        width: '100%',
        background: theme?.primaryColor || 'black',
        color: theme?.secondaryColor || 'black',
      }}
    >
      <FooterContainer justifyContent="space-between">
        <FlexItem>
          <img
            data-testid="footer-logo"
            alt="logo"
            height="32px"
            src={withPrefix(theme?.secondaryLogo || theme?.logo || '')}
            style={{ filter: theme?.secondaryLogo ? '' : 'grayscale(1)' }}
          />
        </FlexItem>
        <FlexItem>
          <ColumnContainer data-testid="footer-all-columns">
            {editMode && columns.length < 3 && (
              <Flex
                direction="column"
                key="column-add"
                style={{
                  gap: '1em',
                  height: 'auto',
                  width: 'auto',
                  color: theme?.secondaryColor || 'black',
                }}
              >
                <AddColumn dispatch={dispatch} theme={theme} />
              </Flex>
            )}
            {columns.map((column: FooterColumn, columnIndex: number) => {
              const ColumnComponent = editMode
                ? editable(
                    StyledColumn,
                    [
                      <EditColumn
                        {...{
                          dispatch,
                          columnIndex,
                          initName: column.name,
                          theme,
                        }}
                      />,
                      <DeleteColumn {...{ dispatch, columnIndex, theme }} />,
                    ],
                    theme,
                  )
                : StyledColumn
              return (
                <Flex
                  direction="column"
                  key={`column-${column.id}`}
                  style={{ gap: '1em' }}
                >
                  <ColumnComponent>
                    <Typography
                      style={{
                        fontSize: '20px',
                        color: theme?.secondaryColor || 'black',
                      }}
                    >
                      {column.name}
                    </Typography>
                  </ColumnComponent>
                  {column.links.map((link, linkIndex) => {
                    const LinkComponent = editMode
                      ? editable(
                          StyledLink,
                          [
                            <EditLink
                              {...{
                                dispatch,
                                columnIndex,
                                linkIndex,
                                initText: link.text,
                                initUrl: link.url,
                                theme,
                              }}
                            />,
                            <DeleteLink
                              {...{
                                dispatch,
                                columnIndex,
                                linkIndex,
                                theme,
                              }}
                            />,
                          ],
                          theme,
                        )
                      : StyledLink
                    return (
                      <LinkComponent key={`link-${link.id}`}>
                        <Link
                          style={{
                            fontSize: '16px',
                            color: theme?.secondaryColor || 'black',
                          }}
                          href={link.url}
                          target="_blank"
                        >
                          {link.text}
                        </Link>
                      </LinkComponent>
                    )
                  })}
                  {editMode && column.links.length < 3 && (
                    <AddLink {...{ dispatch, columnIndex, theme }} />
                  )}
                </Flex>
              )
            })}
          </ColumnContainer>
        </FlexItem>
      </FooterContainer>
    </div>
  )
}

export default Footer
