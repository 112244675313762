import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  IconButton,
} from '@deliveryhero/armor'
import { DeleteIcon } from '@deliveryhero/armor-icons'

import { Theme } from '../../../../global/types'
import { CustomDialogActions, CustomDialogTitle } from '../common.components'
import { Action } from '../common.types'
import { REMOVE_LINK } from '../dataLayer'

type DeleteLinkProps = {
  dispatch: React.Dispatch<Action>
  columnIndex: number
  linkIndex: number
  theme?: Theme
}

const DeleteLink = ({
  dispatch,
  columnIndex,
  linkIndex,
  theme,
}: DeleteLinkProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [t] = useTranslation()

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        width="441px"
      >
        <CustomDialogTitle>
          {t('LandingPage.Footer.DeleteLinkTitle')}
        </CustomDialogTitle>
        <DialogContent paddingTop="2em">
          <Typography paragraph>
            {t('LandingPage.Footer.DeleteLinkDescription')}
          </Typography>
        </DialogContent>
        <CustomDialogActions>
          <Button
            data-testid="footer-delete-link-cancel-button"
            secondary
            onClick={() => setDialogOpen(false)}
          >
            {t('Home.Cancel')}
          </Button>
          <Button
            data-testid="footer-delete-link-delete-button"
            danger
            onClick={() => {
              dispatch({ type: REMOVE_LINK, columnIndex, linkIndex })
              setDialogOpen(false)
            }}
          >
            {t('Home.Delete')}
          </Button>
        </CustomDialogActions>
      </Dialog>
      <IconButton
        data-testid="footer-delete-link-launch-button"
        onClick={() => setDialogOpen(true)}
      >
        <DeleteIcon color={theme?.secondaryColor || 'black'} />
      </IconButton>
    </>
  )
}

export default DeleteLink
