import React from 'react'
import { toast } from 'react-toastify'

export const toastError = (title, body) => {
  toast.configure()
  toast.error(
    <div data-test="error-toast">
      <div style={{ marginBottom: '0.5em' }}>{title}</div>
      <div>{body}</div>
    </div>,
  )
}
