import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, styled } from '@deliveryhero/armor'

import { Theme } from '../../../global/types'
import { report } from '../../../utils/gtm'
import { CTA_CLICK } from '../../../utils/gtm/constants'

const MobileCTAContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 24px;
  box-shadow: rgba(185, 185, 185, 0.44) 0px 0px 24px 0px;
  position: fixed;
  bottom: 0;
  background: white;
  z-index: 1000;
  display: none;

  @media (max-width: 599px) {
    display: block;
  }
`

const MobileCTAButton = ({
  theme,
  elemRef,
}: {
  theme: Theme
  elemRef: React.RefObject<HTMLElement>
}) => {
  const [t] = useTranslation()

  return (
    <MobileCTAContainer>
      <Button
        data-testid="cta-button"
        wide
        onClick={() => {
          report({
            event: CTA_CLICK,
          })
          if (elemRef.current) {
            elemRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }}
        style={{
          background: theme.primaryColor,
          border: 'none',
          color: theme.secondaryColor,
        }}
      >
        {t('LandingPage.CTAText')}
      </Button>
    </MobileCTAContainer>
  )
}

export default MobileCTAButton
