import { Script } from 'gatsby'
import React from 'react'

const REFRESH_INTERVAL_IN_MS = 290 * 1000
const TURNSTILE_SCRIPT_SRC =
  'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback'

type Props = {
  sitekey: string
  refreshInterval?: number
  onTokenObtained: (string) => void
}

export const TurnstileCaptcha = ({
  sitekey,
  refreshInterval = REFRESH_INTERVAL_IN_MS,
  onTokenObtained,
}: Props) => {
  const ref = React.useRef()

  React.useEffect(() => {
    window.onloadTurnstileCallback = () => {
      window.turnstileWidgetId = window.turnstile.render(ref.current, {
        sitekey,
        callback: onTokenObtained,
        theme: 'light',
      })
    }
    const interval = setInterval(
      () => window.turnstile.reset(window.turnstileWidgetId),
      refreshInterval,
    )
    return () => {
      clearInterval(interval)
      window.turnstile?.remove(window.turnstileWidgetId)
    }
  }, [sitekey, onTokenObtained, refreshInterval])

  return (
    <>
      <Script src={TURNSTILE_SCRIPT_SRC} async defer />
      <div ref={ref} />
    </>
  )
}
