import React from 'react'
import styled, { keyframes } from 'styled-components'

const BLUE_PRIMARY = '#0a39b7'
const GREY_NEUTRAL = '#f4f4f4'

const LoaderTrack = styled.circle`
  stroke-width: 5px;
  stroke: ${GREY_NEUTRAL};
  fill: transparent;
`
const arcAnimation = keyframes`
0% {
  stroke-dasharray: 40 242.6;
  stroke-dashoffset: 0;
}
50% {
  stroke-dasharray: 141.3;
  stroke-dashoffset: 141.3;
}
100% {
  stroke-dasharray: 40 242.6;
  stroke-dashoffset: 282.6;
}
`
const LoaderArc = styled.circle`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  fill: none;
  stroke-width: 5px;
  stroke-linecap: round;
  stroke: ${BLUE_PRIMARY};
  stroke-dasharray: 242.6;
  animation: ${arcAnimation} 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
`

export const LoadingSpinner = () => (
  <div>
    <svg height="50" width="50" viewBox="0 0 100 100">
      <LoaderTrack cx="25" cy="25" r="22.5" />
      <LoaderArc cx="25" cy="25" r="22.5" />
    </svg>
  </div>
)
