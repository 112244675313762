import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  Button,
  IconButton,
  Typography,
} from '@deliveryhero/armor'
import { DeleteIcon } from '@deliveryhero/armor-icons'

import { Theme } from '../../../../global/types'
import { CustomDialogActions, CustomDialogTitle } from '../common.components'
import { Action } from '../common.types'
import { REMOVE_COLUMN } from '../dataLayer'

type DeleteColumnProps = {
  dispatch: React.Dispatch<Action>
  columnIndex: number
  theme?: Theme
}

const DeleteColumn = ({ dispatch, columnIndex, theme }: DeleteColumnProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [t] = useTranslation()

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        width="441px"
      >
        <CustomDialogTitle>
          {t('LandingPage.Footer.DeleteColumnTitle')}
        </CustomDialogTitle>
        <DialogContent paddingTop="2em">
          <Typography paragraph>
            {t('LandingPage.Footer.DeleteColumnDescription')}
          </Typography>
        </DialogContent>
        <CustomDialogActions>
          <Button
            data-testid="footer-delete-column-cancel-button"
            secondary
            onClick={() => setDialogOpen(false)}
          >
            {t('Home.Cancel')}
          </Button>
          <Button
            danger
            data-testid="footer-delete-column-delete-button"
            onClick={() => {
              dispatch({ type: REMOVE_COLUMN, columnIndex })
              setDialogOpen(false)
            }}
          >
            {t('Home.Delete')}
          </Button>
        </CustomDialogActions>
      </Dialog>
      <IconButton
        data-testid="footer-delete-column-launch-button"
        onClick={() => setDialogOpen(true)}
      >
        <DeleteIcon color={theme?.secondaryColor || 'black'} />
      </IconButton>
    </>
  )
}

export default DeleteColumn
