import styled from 'styled-components'

import {
  DialogActions,
  DialogTitle,
  FlexItem,
  Flex,
  IconButton,
} from '@deliveryhero/armor'

export const StyledLink = styled(FlexItem)`
  max-width: 169px;
  width: 169px;
  padding: 2px 14.5px;
  @media (max-width: 375px) {
    max-width: 300px;
    width: 300px;
  }
`
export const StyledColumn = styled(FlexItem)`
  max-width: 169px;
  width: 169px;
  padding: 2px 10.5px;
  @media (max-width: 375px) {
    max-width: 300px;
    width: 300px;
  }
`

export const FooterContainer = styled(Flex)`
  flex-flow: row wrap;
  max-width: 960px;
  margin: 0px auto;
  padding: 40px 50px;
  gap: 46px;

  @media (max-width: 375px) {
    max-width: 375px;
    flex-flow: column wrap;
    padding: 40px 38.5px;
  }

  ${
    /* add margin in mobile view to ensure the CTA doesn't cover the footer links */ ' '
  }
  @media (max-width: 599px) {
    margin-bottom: 96px;
  }
`
export const ColumnContainer = styled(Flex)`
  gap: 1em;
  flex-flow: row wrap;

  @media (max-width: 375px) {
    gap: 46px;
    flex-flow: column wrap;
  }
`

export const CustomDialogTitle = styled(DialogTitle)`
  padding-bottom: 0;
  margin-bottom: -32px;
`
export const CustomDialogActions = styled(DialogActions)`
  padding-top: 24px !important;
`

export function validateURL(url: string): boolean {
  try {
    const parsed = new URL(url)
    if (
      !parsed.hostname.includes('.') ||
      parsed.hostname.lastIndexOf('.') === parsed.hostname.length - 1
    )
      return false
    return true
  } catch (e) {
    return false
  }
}

export const FooterActionButton = styled(IconButton)`
  width: 100%;
  height: auto;
  font-size: 14px;
`
