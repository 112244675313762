export const convertRulesToCities = (rules: string) =>
  convertRulesToObjects(rules).map(({ city }) => city)

export const convertRulesToWorkflows = (rules: string) =>
  convertRulesToObjects(rules).map(({ workflow }) => workflow)

export const convertRulesToLocations = (rules: string) =>
  convertRulesToObjects(rules).map(({ location }) => location)

export const convertRulesToObjects = (rules: string) =>
  rules
    .split(';')
    .map(r => r.trim().split('$'))
    .map(([city, location, workflow]) => ({ city, location, workflow }))
    .filter(c => c.city.length > 0)

export const hexToRGB = (hex: string, alpha?: string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  return `rgb(${r}, ${g}, ${b})`
}

export const isGodMode = () =>
  typeof window !== 'undefined' &&
  window.location.search.includes('godmode=true')

export const subtractYearsFromDate = (minimumAge: number) => {
  const date = new Date()
  date.setHours(0, 0, 0)
  date.setFullYear(date.getFullYear() - minimumAge)
  return date
}

export const generateRangeToNumber = (maxYearRange: number) =>
  Array.from({ length: 100 }, (_, idx) => maxYearRange - 98 + idx)
