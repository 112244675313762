import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  FormField,
  TextInput,
  FormFieldMessage,
  Button,
  IconButton,
} from '@deliveryhero/armor'
import { EditIcon } from '@deliveryhero/armor-icons'

import { Theme } from '../../../../global/types'
import {
  CustomDialogTitle,
  CustomDialogActions,
  validateURL,
} from '../common.components'
import { Action } from '../common.types'
import { EDIT_LINK } from '../dataLayer'

type EditLinkProps = {
  dispatch: React.Dispatch<Action>
  columnIndex: number
  linkIndex: number
  initText: string
  initUrl: string
  theme?: Theme
}

const EditLink = ({
  dispatch,
  columnIndex,
  linkIndex,
  initText,
  initUrl,
  theme,
}: EditLinkProps) => {
  const [url, setUrl] = React.useState(initUrl)
  const validURL = validateURL(url)
  const inputError = url !== '' && !validURL

  const [text, setText] = React.useState(initText)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [t] = useTranslation()

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        width="441px"
      >
        <CustomDialogTitle>
          {t('LandingPage.Footer.EditLinkTitle')}
        </CustomDialogTitle>
        <DialogContent paddingTop="2em">
          <FormField style={{ marginBottom: '1em' }}>
            <TextInput
              data-testid-input="footer-edit-link-text-input"
              wide
              name="text"
              label={t('LandingPage.Footer.InputLinkText')}
              value={text}
              onChange={e => {
                setText(e.target.value)
              }}
            />
          </FormField>
          <FormField>
            <TextInput
              data-testid-input="footer-edit-link-url-input"
              wide
              name="url"
              label={t('LandingPage.Footer.InputLinkURL')}
              value={url}
              onChange={e => {
                setUrl(e.target.value)
              }}
              error={inputError}
            />
            <FormFieldMessage error={inputError} oneLine>
              {inputError ? (
                <span data-testid="footer-edit-link-error">
                  {t('LandingPage.Footer.LinkInvalidError')}
                </span>
              ) : (
                'Eg: https://rider.foodpanda.sg'
              )}
            </FormFieldMessage>
          </FormField>
        </DialogContent>
        <CustomDialogActions>
          <Button tertiary onClick={() => setDialogOpen(false)}>
            {t('Home.Cancel')}
          </Button>
          <Button
            data-testid="footer-edit-link-save-button"
            primary
            onClick={() => {
              dispatch({ type: EDIT_LINK, columnIndex, linkIndex, text, url })
            }}
            disabled={!validURL || text === ''}
          >
            {t('LandingPage.Save')}
          </Button>
        </CustomDialogActions>
      </Dialog>
      <IconButton
        data-testid="footer-edit-link-launch-button"
        onClick={() => setDialogOpen(true)}
      >
        <EditIcon color={theme?.secondaryColor || 'black'} />
      </IconButton>
    </>
  )
}

export default EditLink
