import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  FormField,
  TextInput,
  Button,
} from '@deliveryhero/armor'
import { PlusIcon } from '@deliveryhero/armor-icons'

import { Theme } from '../../../../global/types'
import {
  CustomDialogTitle,
  CustomDialogActions,
  StyledColumn,
  FooterActionButton,
} from '../common.components'
import { Action } from '../common.types'
import { ADD_COLUMN } from '../dataLayer'

type AddColumProps = {
  dispatch: React.Dispatch<Action>
  theme?: Theme
}

const AddColumn = ({ dispatch, theme }: AddColumProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [columnName, setColumnName] = React.useState('')

  const [t] = useTranslation()

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        width="441px"
      >
        <CustomDialogTitle
          description={t('LandingPage.Footer.AddColumnDescription')}
        >
          {t('LandingPage.Footer.AddColumnTitle')}
        </CustomDialogTitle>
        <DialogContent paddingTop="2em">
          <FormField>
            <TextInput
              wide
              data-testid-input="footer-add-column-name-input"
              name="columnName"
              label={t('LandingPage.Footer.InputColumnName')}
              value={columnName}
              onChange={e => {
                setColumnName(e.target.value)
              }}
            />
          </FormField>
        </DialogContent>
        <CustomDialogActions>
          <Button secondary onClick={() => setDialogOpen(false)}>
            {t('Home.Cancel')}
          </Button>
          <Button
            disabled={columnName === ''}
            data-testid="footer-add-column-add-button"
            onClick={() => {
              dispatch({ type: ADD_COLUMN, columnName })
              setDialogOpen(false)
            }}
          >
            {t('LandingPage.Add')}
          </Button>
        </CustomDialogActions>
      </Dialog>
      <StyledColumn
        style={{
          position: 'relative',
          border: `thin solid ${theme?.secondaryColor || 'black'}`,
          borderRadius: '5px',
        }}
      >
        <FooterActionButton
          data-testid="footer-add-column-launch-button"
          onClick={() => setDialogOpen(true)}
          color={theme?.secondaryColor || 'black'}
        >
          <PlusIcon
            style={{
              color: theme?.secondaryColor || 'black',
              marginRight: '6px',
              width: '1em',
            }}
          />{' '}
          {t('LandingPage.Footer.AddColumnButtonText')}
        </FooterActionButton>
      </StyledColumn>
    </>
  )
}

export default AddColumn
