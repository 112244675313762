import { createContext } from 'react'

export type GlobalState = {
  selectedCountryCode: string | undefined
}

export type GlobalContext = GlobalState & {
  setSelectedCountry: (newCountry: string) => void
}

export const GlobalContext = createContext<Partial<GlobalContext>>({})
