import React, { useEffect, useState } from 'react'

import {
  Dropdown,
  Flex,
  FlexItem,
  TextInput,
  styled,
} from '@deliveryhero/armor'

import { onFormPropertyChangeType } from '../../../components/LandingPageRenderer'
import { config } from '../../../config/global'
import { countries } from '../../../utils/countryCodes'
import { DenormalizedField } from '../selectors'

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  background: 'white';
  .TextInput-Input {
    padding-right: 0px;
  }
`

type IProps = {
  countryCode: string
  field: DenormalizedField
  index: number
  onChange: onFormPropertyChangeType
}
const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })

const dialCodeOptions = [
  ...new Set(
    countries
      .sort((a, b) => collator.compare(a.dialCode, b.dialCode))
      .map(it => it.dialCode),
  ),
].map(dc => ({
  label: `+${dc}`,
  value: `+${dc}`,
}))

export const PhoneNumberInput: React.FC<IProps> = ({
  countryCode,
  field,
  index,
  onChange,
}) => {
  const userCountryDialCode =
    countries.find(it => countryCode.toUpperCase() === it.code)?.dialCode ||
    config.defaultCountryCode
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberDialCode, setPhoneNumberDialCode] = useState(
    `+${userCountryDialCode}`,
  )

  useEffect(() => {
    onChange({
      name: field.name,
      value: phoneNumberDialCode + phoneNumber,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, phoneNumberDialCode])

  return (
    <Flex width="100%" justifyContent="space-between">
      <FlexItem width="100px" flexShrink={0}>
        <StyledDropdown
          disabled={config.disableCountryCodePicker}
          data-testid-input="country-dial-codes"
          style={{ background: 'white' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPhoneNumberDialCode(e.target.value)
          }}
          value={phoneNumberDialCode}
          options={dialCodeOptions}
        />
      </FlexItem>
      <FlexItem flexGrow={1} marginLeft="16px">
        <TextInput
          data-testid-input="phone-number-input"
          style={{ background: 'white' }}
          key={field.id}
          name={field.name}
          label={field.text}
          value={phoneNumber}
          onChange={e => {
            setPhoneNumber(`${e.target.value}`)
          }}
          autoFocus={index === 0}
        />
      </FlexItem>
    </Flex>
  )
}
