import { useLocation } from '@gatsbyjs/reach-router'
import { Link } from 'gatsby'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
  styled,
  HeaderNavigation,
  HeaderNavigationTitle,
  HeaderNavigationLinks,
  HeaderNavigationLink,
  HeaderNavigationSelector,
  HeaderNavigationAction,
  IconButton,
} from '@deliveryhero/armor'
import { LogoutIcon } from '@deliveryhero/armor-icons'
import { HeaderNavigationMenuContentLogistics } from '@deliveryhero/armor-vendors'
import { CLOUDFLARE_ACCESS_LOGOUT_PATH } from '@deliveryhero/logistics-auth-interceptors'

import { GlobalContext } from '../../global/Context'
import { clearTokens } from '../../utils/requests'

import { NavBarDodoIcon } from './DodoIcon'
import { useGetCountries } from './apis'

const DodoHeaderNavigationWrapper = styled.div`
  border-bottom: 1px solid #dcdcdc;
`

const DodoHeaderNavigation = styled(HeaderNavigation)`
  .HeaderNavigationMenu-NavigationMenuContentContainer {
    z-index: 2000;
  }
  .HeaderNavigationMenu-NavigationMenuItem {
    line-height: 1;
    padding: 0;
  }
`

const DodoHeaderNavigationSelector = styled(HeaderNavigationSelector)`
  font-weight: 400;
`

const StyledHeaderNavigationTitle = styled(HeaderNavigationTitle)`
  display: flex;
  justify-items: center;
  align-items: center;
`
const StyledHeaderNavigationTitleText = styled.span`
  padding-left: 5px;
`

type CountryOption = {
  value: string
  label: string
}

export const NavBar: React.FC = () => {
  const { selectedCountryCode, setSelectedCountry } = useContext(GlobalContext)

  const { data: countries = [] } = useGetCountries()

  const location = useLocation()
  const { t } = useTranslation()

  const onCountrySelected = (selected: CountryOption) => {
    setSelectedCountry(selected.value)
  }

  const queries = () =>
    typeof window !== 'undefined' ? window.location.search : ''

  const links = [
    {
      id: 12,
      to: `/app${queries()}`,
      label: 'Projects',
    },
    {
      id: 13,
      to: `/app/reports${queries()}`,
      label: 'Reports',
    },
    {
      id: 14,
      to: `/app/themes`,
      label: 'Themes',
    },
  ]

  const logout = () => {
    clearTokens()

    if (process.env.NODE_ENV !== 'development') {
      window.location.href = CLOUDFLARE_ACCESS_LOGOUT_PATH
    }
  }

  return (
    <DodoHeaderNavigationWrapper>
      <DodoHeaderNavigation
        links={
          <HeaderNavigationLinks pathname={location.pathname}>
            {links.map(menuItem => (
              <HeaderNavigationLink
                to={menuItem.to}
                key={menuItem.to}
                tag={Link}
                isActive={location.pathname.endsWith(menuItem.to)}
              >
                {menuItem.label}
              </HeaderNavigationLink>
            ))}
          </HeaderNavigationLinks>
        }
        navigationMenuTitle={
          <StyledHeaderNavigationTitle to="/app" className="nav-title">
            <NavBarDodoIcon />
            <StyledHeaderNavigationTitleText>
              {t('Home.DodoLandingPageEditor')}
            </StyledHeaderNavigationTitleText>
          </StyledHeaderNavigationTitle>
        }
        navigationMenuContent={<HeaderNavigationMenuContentLogistics />}
        selector={
          <DodoHeaderNavigationSelector
            selected={countries.find(
              country => country.code === selectedCountryCode,
            )}
            navigationSelectorParams={{
              label: 'Country',
              options: countries.map(({ name, code }) => ({
                label: name,
                value: code,
              })),
              value: selectedCountryCode,
            }}
            onOptionSelect={option => onCountrySelected(option)}
          />
        }
        navigationAction={
          <HeaderNavigationAction>
            <IconButton onClick={logout}>
              <LogoutIcon medium />
            </IconButton>
          </HeaderNavigationAction>
        }
      />
    </DodoHeaderNavigationWrapper>
  )
}
