import React from 'react'

export default function useIntersectionObserver(ref, threshold = 0) {
  const [intersectionData, setIntersectionData] = React.useState(null)

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const [entry] = entries
        setIntersectionData(entry)
      },
      {
        threshold,
      },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref])

  return intersectionData
}
