export const CHARS_ONLY_VALIDATION = 'chars_only'
export const PHONE_NUMBER_VALIDATION = 'phone_number'
export const NUMBERS_ONLY_VALIDATION = 'numbers_only'
export const EMAIL_VALIDATION = 'email'
export const NONE_VALIDATION = 'none'

export const SUPPORTED_VALIDATIONS = [
  CHARS_ONLY_VALIDATION,
  PHONE_NUMBER_VALIDATION,
  NUMBERS_ONLY_VALIDATION,
  EMAIL_VALIDATION,
  NONE_VALIDATION,
]

export const validateNumberOnly = (content: string) => /^[\d\s]+$/.test(content)

export const validateChartOnly = (content: string) =>
  /^[a-zA-Z\s]+$/.test(content)

export const validateEmail = (content: string) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]{2,})+$/.test(
    content,
  )

// Basic validation only, Twilio does the rest
export const validatePhoneNumber = (content: string) =>
  /^\+?[-()\d\s]+$/.test(content)

export const validateInternationalPhoneNumber = (content: string) =>
  /^\+[1-9]\d{1,14}$/.test(content.replace(/[\s()]+/g, ''))

export const isEmptyOrUndefined = (value: string) =>
  typeof value === 'undefined' || value === ''

export const VALIDATIONS_MAP = {
  [CHARS_ONLY_VALIDATION]: validateChartOnly,
  [PHONE_NUMBER_VALIDATION]: validatePhoneNumber,
  [NUMBERS_ONLY_VALIDATION]: validateNumberOnly,
  [EMAIL_VALIDATION]: validateEmail,
  [NONE_VALIDATION]: () => true,
}

export const check = (validation: string, value: string) =>
  VALIDATIONS_MAP[validation](value)
