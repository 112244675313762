import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  FormField,
  TextInput,
  Button,
  IconButton,
} from '@deliveryhero/armor'
import { EditIcon } from '@deliveryhero/armor-icons'

import { Theme } from '../../../../global/types'
import { CustomDialogTitle, CustomDialogActions } from '../common.components'
import { Action } from '../common.types'
import { EDIT_COLUMN } from '../dataLayer'

type EditColumProps = {
  dispatch: React.Dispatch<Action>
  columnIndex: number
  initName: string
  theme?: Theme
}

const EditColumn = ({
  dispatch,
  columnIndex,
  initName,
  theme,
}: EditColumProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [columnName, setColumnName] = React.useState(initName)

  const [t] = useTranslation()

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        width="441px"
      >
        <CustomDialogTitle>
          {t('LandingPage.Footer.EditColumnTitle')}
        </CustomDialogTitle>
        <DialogContent paddingTop="2em">
          <FormField>
            <TextInput
              data-testid-input="footer-edit-column-name-input"
              wide
              name="columnName"
              label={t('LandingPage.Footer.InputColumnName')}
              value={columnName}
              onChange={e => {
                setColumnName(e.target.value)
              }}
            />
          </FormField>
        </DialogContent>
        <CustomDialogActions>
          <Button secondary onClick={() => setDialogOpen(false)}>
            {t('Home.Cancel')}
          </Button>
          <Button
            data-testid="footer-edit-column-save-button"
            disabled={columnName === ''}
            onClick={() => {
              dispatch({ type: EDIT_COLUMN, columnName, columnIndex })
              setDialogOpen(false)
            }}
          >
            {t('LandingPage.Save')}
          </Button>
        </CustomDialogActions>
      </Dialog>
      <IconButton
        data-testid="footer-edit-column-launch-button"
        onClick={() => setDialogOpen(true)}
      >
        <EditIcon color={theme?.secondaryColor} />
      </IconButton>
    </>
  )
}

export default EditColumn
