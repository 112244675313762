import { config } from '../../config/global'
import { LandingPageContentType } from '../../containers/landing-page/selectors'

const parseWorkflow = (workflow: string) => {
  const data = workflow.split('$')

  return { locationId: data[1], workflowId: data[2] }
}

export const getQueryParametersFromURL = () => {
  const params = new URLSearchParams(window.location.search)
  return Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  )
}

export const getApplicantsFieldsFromURL = () =>
  Object.fromEntries(
    Object.entries(getQueryParametersFromURL()).filter(
      ([k]) =>
        k.startsWith('utm_') || k.startsWith('field_') || k === 'company',
    ),
  )

export const requestCreator = (
  landingPageContent: LandingPageContentType,
  data: Record<string, string | boolean>,
) => {
  const { locationId, workflowId } = parseWorkflow(
    landingPageContent.workflow.rules
      .replace(/\n/g, '')
      .split(';')
      .find(rule => rule.startsWith(`${data.city}$`)),
  )

  const attributes = [
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'city',
  ]

  const fields = Object.keys(Object.assign(data, getApplicantsFieldsFromURL()))
    .filter(key => !attributes.includes(key) && key !== 'turnstileToken')
    .map(fieldName => {
      if (fieldName === 'age')
        return {
          type: 'applicant_fields',
          attributes: {
            name: 'age_check',
            field_type: 'number',
            value:
              data[fieldName] === true || data[fieldName] === 'yes' ? 1 : 0,
          },
        }

      return {
        type: 'applicant_fields',
        attributes: {
          name: fieldName,
          field_type:
            landingPageContent.fields.find(field => field.name === fieldName)
              ?.type || 'string',
          value: data[fieldName],
        },
      }
    })

  const workflowFromUrl = getQueryParametersFromURL().wf

  return {
    data: {
      type: 'applicants',
      cf: data.turnstileToken,
      attributes: {
        name: `${data.first_name ?? ''} ${data.last_name ?? ''}`.trim(),
        email: data.email,
        phone_number: data.phone_number,
      },
      relationships: {
        workflow: {
          data: {
            id: workflowFromUrl || workflowId,
            type: 'workflows',
          },
        },
        location: {
          data: {
            id: locationId,
            type: 'locations',
          },
        },
        language: {
          data: {
            id: landingPageContent.language.id,
            type: 'languages',
          },
        },
        fields: {
          data: [
            ...fields,
            {
              type: 'applicant_fields',
              attributes: {
                name: 'name_(shown_to_customers)',
                field_type: 'string',
                value: config.overwriteShownName
                  ? data.last_name
                  : data.first_name,
              },
            },
            {
              type: 'applicant_fields',
              attributes: {
                name: 'last_name',
                field_type: 'string',
                value: data.last_name ?? '',
              },
            },
            {
              type: 'applicant_fields',
              attributes: {
                name: 'registration_source',
                field_type: 'string',
                value: 'web',
              },
            },
          ],
        },
      },
    },
  }
}
