import { withPrefix } from 'gatsby'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { styled, Button, Grid, GridItem } from '@deliveryhero/armor'

import { config } from '../config/global'
import { FormGenerator } from '../containers/landing-page/FormGenerator'
import { LandingPageHeader } from '../containers/landing-page/landing-page-header'
import { rendererMapping } from '../containers/landing-page/rendererMapping'
import { DenormalizedLandingPage } from '../containers/landing-page/selectors'
import useIntersectionObserver from '../hooks/useIntersectionObserver'
import { getDirectionOrDefault } from '../utils/languageUtils'
import { renderer } from '../utils/renderer'

import MobileCTAButton from './Molecules/MobileCTAButton'
import Footer from './Organisms/Footer'
import { FooterColumn } from './Organisms/Footer/common.types'

const BrandedHeader = ({
  theme,
  landingPageContents,
  selectedLandingPageContentIdx,
}: Pick<DenormalizedLandingPage, 'theme' | 'landingPageContents'> & {
  selectedLandingPageContentIdx: number
}) => {
  const [t] = useTranslation()

  const StyledImg = styled.img`
    height: 30px;
  `
  const StyledGrid = styled(Grid)`
    .pull-right-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  `

  const StyledButton = styled(Button)`
    border: 0;
    background-color: ${theme.primaryColor};
    color: ${theme.secondaryColor};
    button + & {
      margin-left: 5px;
    }
    &.true-bg {
      background-color: ${theme.primaryColor}75;
    }
    $.false-bg {
      background: none;
    }
  `

  return (
    <StyledGrid>
      <GridItem xs={6} sm={8}>
        <StyledImg src={withPrefix(theme.logo)} alt={theme.name} />
      </GridItem>
      <GridItem xs={6} sm={4} className="pull-right-content">
        {landingPageContents
          .map((lpc, idx) => ({
            className: `${selectedLandingPageContentIdx === idx}-bg`,
            children: `${lpc.language.id}`,
          }))
          .map(language => (
            <StyledButton {...language} />
          ))}
        <StyledButton>{t('LandingPage.SignIn')}</StyledButton>
      </GridItem>
    </StyledGrid>
  )
}

export type onFormPropertyChangeType = (props: {
  name: string
  value: string | boolean
}) => void

export const LandingPageRenderer: React.FC<{
  landingPage: DenormalizedLandingPage
  selectedLandingPageContentIdx: number
  previewMode?: boolean
  onFormSubmit?: (data: Record<string, string>) => void
  onChangeLandingPageContextIdx: (index: number) => void
  onFooterEdit?: (data: FooterColumn[]) => void
  onFieldChange?: (
    data: Record<string, string>,
    name: string,
    value: string,
  ) => void
}> = ({
  landingPage,
  selectedLandingPageContentIdx,
  previewMode = false,
  onFormSubmit,
  onFieldChange,
  onChangeLandingPageContextIdx,
  onFooterEdit,
}) => {
  const [formData, setFormData] = useState({})
  const formRef = React.useRef(null)
  const titleRef = React.useRef(null)
  const onFormPropertyChange: onFormPropertyChangeType = ({ name, value }) => {
    const newData = { ...formData, ...{ [name]: value } }
    setFormData(newData)
    if (onFieldChange) onFieldChange(newData, name, value.toString())
  }

  const intersectionData = useIntersectionObserver(formRef, 0.04)
  const { theme, landingPageContents } = landingPage
  const {
    language: { id: langId },
    content,
  } = landingPageContents[selectedLandingPageContentIdx]
  const { i18n } = useTranslation()
  const direction = getDirectionOrDefault(langId, i18n.dir(langId))
  return (
    landingPageContents.length > 0 && (
      <div ref={titleRef} dir={direction}>
        {renderer(
          previewMode,
          content.structure,
          rendererMapping,
          landingPage.theme,
          'root',
          {
            $FORM: (
              <FormGenerator
                formRef={formRef}
                landingPage={landingPage}
                activeLandingPageContentIdx={selectedLandingPageContentIdx}
                onChange={onFormPropertyChange}
                data={formData}
                previewMode={previewMode}
                onSubmit={onFormSubmit}
              />
            ),
            $HEAD_BRANDED: (
              <BrandedHeader
                theme={theme}
                landingPageContents={landingPageContents}
                selectedLandingPageContentIdx={selectedLandingPageContentIdx}
              />
            ),
            $HEADER: (
              <LandingPageHeader
                landingPage={landingPage}
                selectedLandingPageContentIdx={selectedLandingPageContentIdx}
                onChangeLandingPageContextIdx={onChangeLandingPageContextIdx}
              />
            ),
          },
        )}
        {config.enableCTAButton && !intersectionData?.isIntersecting && (
          <MobileCTAButton theme={theme} elemRef={titleRef} />
        )}
        <Footer
          onEdit={onFooterEdit}
          editMode={previewMode}
          theme={theme}
          key={selectedLandingPageContentIdx}
          data={
            landingPageContents[selectedLandingPageContentIdx].content.footer ||
            []
          }
        />
      </div>
    )
  )
}
