import combineURLs from 'axios/lib/helpers/combineURLs'
import useSWR, { mutate } from 'swr'

import { makeRequest } from './requests'

const useAPI = <T>(path: string) => {
  const { data, error, isValidating } = useSWR<T>(
    path,
    (key: string) => makeRequest('get', key, {}),
    { revalidateOnFocus: false },
  )

  return { data, error, loading: isValidating }
}

export const getUseAPI = (basePath: string) => {
  // current workaround because the base paths are
  // sometimes not set during the build
  const resolvePath = (path: string) => combineURLs(basePath || '/', path)

  return {
    useAPI: <T>(path: string) => useAPI<T>(resolvePath(path)),
    mutate: (path: string) => mutate(resolvePath(path)),
  }
}
