import {
  FORM_1_SUBMIT,
  PAGE_VIEW,
  FORM_2_SUBMIT,
  CTA_CLICK,
  FORM_2_SUBMIT_SUCCESSFUL,
  FORM_2_SUBMIT_ERROR,
} from './constants'

type GTMStore = {
  event?: string
  pageUrlPath?: string
  pageType?: string
  // eslint-disable-next-line
  LP_Version?: string
  // eslint-disable-next-line
  LP_Variation?: string
  formElements?: number
  cityEntered?: string
  vehicleEntered?: string
  formLocation?: string
  Brand?: string
  error?: string
}

let store: GTMStore = {}

declare global {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const gtag: Function
  interface Window {
    dataLayer?: GTMStore[]
  }
}

export const report = (props: GTMStore) => {
  store = { ...store, ...props }

  if (props.event && typeof window !== 'undefined' && window.dataLayer) {
    const { event, pageUrlPath, pageType, Brand, cityEntered, vehicleEntered } =
      store

    switch (event) {
      case PAGE_VIEW:
        window.dataLayer.push({
          event,
          pageUrlPath,
        })
        break
      case FORM_1_SUBMIT:
      case FORM_2_SUBMIT:
      case FORM_2_SUBMIT_ERROR:
      case FORM_2_SUBMIT_SUCCESSFUL:
        window.dataLayer.push({
          event,
          pageType,
          LP_Version: 'new',
          LP_Variation: 'new',
          formElements: 2,
          formLocation: 'side',
          Brand,
          cityEntered,
          vehicleEntered,
        })
        break
      case CTA_CLICK:
        window.dataLayer.push({
          event,
        })
        break
      default:
        break
    }
  }
}
