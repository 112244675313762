/* eslint-disable no-param-reassign */
import { v4 } from 'uuid'

import { Action, FooterColumn } from './common.types'

export const [
  ADD_COLUMN,
  REMOVE_COLUMN,
  EDIT_COLUMN,
  ADD_LINK,
  REMOVE_LINK,
  EDIT_LINK,
] = [
  'add_column',
  'remove_column',
  'edit_column',
  'add_link',
  'remove_link',
  'edit_link',
]

export function footerDataReducer(draft: FooterColumn[], action: Action) {
  switch (action.type) {
    case ADD_COLUMN:
      draft.unshift({ name: action.columnName, links: [], id: v4() })
      break
    case REMOVE_COLUMN:
      if (action.columnIndex < 0 || action.columnIndex >= draft.length)
        throw new Error('Invalid index')
      draft.splice(action.columnIndex, 1)
      break
    case EDIT_COLUMN:
      if (action.columnIndex < 0 || action.columnIndex >= draft.length)
        throw new Error('Invalid index')
      draft[action.columnIndex].name = action.columnName
      break
    case ADD_LINK:
      draft[action.columnIndex].links.push({
        url: action.url,
        text: action.text,
        id: v4(),
      })
      break
    case REMOVE_LINK:
      if (
        action.columnIndex < 0 ||
        action.columnIndex >= draft.length ||
        action.linkIndex < 0 ||
        action.linkIndex >= draft[action.columnIndex].links.length
      )
        throw new Error('Invalid index')
      draft[action.columnIndex].links.splice(action.linkIndex, 1)
      break
    case EDIT_LINK:
      if (
        action.columnIndex < 0 ||
        action.columnIndex >= draft.length ||
        action.linkIndex < 0 ||
        action.linkIndex >= draft[action.columnIndex].links.length
      )
        throw new Error('Invalid index')
      draft[action.columnIndex].links[action.linkIndex].text = action.text
      draft[action.columnIndex].links[action.linkIndex].url = action.url
      break
    default:
      break
  }
}
