import React from 'react'
import styled from 'styled-components'

const DodoIcon = props => (
  <svg
    id="prefix__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <defs>
      <clipPath id="prefix__clip-path">
        <circle className="prefix__cls-1" cx={512} cy={508.94} r={371.02} />
      </clipPath>
      <style>
        {
          '.prefix__cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:20px}'
        }
      </style>
    </defs>
    {/* eslint-disable max-len */}
    <g clipPath="url(#prefix__clip-path)">
      <path d="M420.48 537.45a70.54 70.54 0 00.13-9.74 40.7 40.7 0 00-1-5.26c-.33-1.24-.69-2.48-1.07-3.72-12.16-38.81-49.47-63.14-90-62.23-35.09 5.24-63 32.66-66 67.87-3.18 38.47 24.67 72.69 63.56 80.28a6.73 6.73 0 001.33.13 7 7 0 006.68-5.07c4.84-17 21.17-29.14 40.09-29.14 1.22 0 2.45.06 3.69.16.65 0 1.3.12 1.95.2a38 38 0 004.74.3 36.29 36.29 0 0026.77-11.64 42.44 42.44 0 008.44-16.84c.13-.69.24-1.39.34-2.09q.22-1.6.35-3.21z" />
      <path d="M373.89 580.59c-14.14.14-26.51 9.08-30.15 21.86a17 17 0 01-16.29 12.33 16.71 16.71 0 01-3.24-.32c-44.4-8.66-75.19-47.75-71.61-90.92a82.58 82.58 0 0122.46-50c-33.28 22.48-49.7 63.43-37.63 101.94 13.82 44.12 60.14 69.51 106.72 60.39a8.32 8.32 0 006.65-9.08c-1.99-18.19 7.12-36.12 23.09-46.2zM806.6 730.1c-53.06 0-100.35-28.28-123.42-73.8-.89-1.76-1.75-3.55-2.55-5.34a66.56 66.56 0 01-1.11-52.59 70.42 70.42 0 0136.62-38.08c.93-.42 1.87-.87 2.78-1.34 28.24-14.31 42.86-45.62 34.74-74.44a19.21 19.21 0 0111.64-23.07 148.33 148.33 0 0152.28-9.53c53 0 100.32 28.26 123.38 73.77 34.31 67.71 4.4 152.1-66.67 188.11a150.58 150.58 0 01-67.69 16.31z" />
      <path d="M1132.28 601.2c-21-125.58-94.44-55.71-160.84-27.47q.15 1.92.24 3.84a153.4 153.4 0 01-8.84 59.43c-14.09 39.59-43 72.08-81.28 91.5a167.93 167.93 0 01-75.5 18.19c-59.36 0-112.3-31.7-138.16-82.73-1-2-2-4-2.86-6a78.37 78.37 0 01-1.31-61.9 82.49 82.49 0 0142.87-44.61c.89-.4 1.79-.83 2.67-1.27a67.5 67.5 0 006.72-3.93c-20.72-37.5-30.1-83.39-23.9-127.28C714.54 260 496.4 170.55 395.7 288.62 267.19 453.5 454.3 369 478.4 599c3.24 33.53-2.76 74.61-5.56 108.34-.31 3.81-.82 7.61-1.36 11.4-29.91 207.77 135.25 334.52 346 323q6.32-.35 12.65-.26c222.12 2.75 343.92-190.48 302.15-440.28zm-611.6-170.82a35.34 35.34 0 1131.46-38.84 35.34 35.34 0 01-31.46 38.84z" />
    </g>
    {/* eslint-enable max-len */}
    <circle className="prefix__cls-1" cx={512} cy={508.94} r={371.02} />
  </svg>
)

const NavBarDodoIcon = styled(DodoIcon)`
  width: 2rem;
`

export { DodoIcon, NavBarDodoIcon }
