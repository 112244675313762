export function validVideoProvider(url: string) {
  try {
    const parsedURL = new URL(url)
    if (
      parsedURL.hostname === 'www.youtube.com' ||
      parsedURL.hostname === 'youtube.com'
    )
      return parsedURL.searchParams.has('v')
    if (
      parsedURL.hostname === 'www.vimeo.com' ||
      parsedURL.hostname === 'vimeo.com'
    )
      return parsedURL.pathname.length > 1
    return false
  } catch (e) {
    return false
  }
}

export function videoEmbedSrc(url: string) {
  if (validVideoProvider(url)) {
    const parsedURL = new URL(url)
    if (
      parsedURL.hostname === 'www.youtube.com' ||
      parsedURL.hostname === 'youtube.com'
    ) {
      const videoId = parsedURL.searchParams.get('v')
      return `https://www.youtube.com/embed/${videoId}`
    }
    if (
      parsedURL.hostname === 'www.vimeo.com' ||
      parsedURL.hostname === 'vimeo.com'
    ) {
      const videoId = parsedURL.pathname
      return `https://player.vimeo.com/video${videoId}`
    }
  }
  return url
}
