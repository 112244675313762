import { withPrefix } from 'gatsby'
import React from 'react'

import { Button, Grid, GridItem, styled } from '@deliveryhero/armor'

import { DenormalizedLandingPage } from './selectors'
import { hexToRGB } from './utils'

const Header = styled.header`
  display: flex;
  height: 74px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 35px;

  @media only screen and (min-width: 600px) {
    height: 106px;
    justify-content: space-between;
    padding: 0 54px;
  }
`
const ThemeButton = styled(Button)<{ active: boolean }>`
  color: ${props => props.color};
  text-transform: uppercase;
  background-color: ${props =>
    props.active ? hexToRGB(props.color, '.1') : '#fff'};
  margin-left: 8px;
  &:hover {
    color: ${props => props.color} !important;
  }
`

const Image = styled.img`
  height: 21px;
  width: auto;
  margin: 0;

  @media only screen and (min-width: 600px) {
    height: 30px;
  }
`

export const LandingPageHeader: React.FC<{
  landingPage: DenormalizedLandingPage
  selectedLandingPageContentIdx: number
  onChangeLandingPageContextIdx: (index: number) => void
}> = ({
  landingPage,
  selectedLandingPageContentIdx,
  onChangeLandingPageContextIdx,
}) => {
  const handleLandingPageContextChange = (index: number) =>
    index !== selectedLandingPageContentIdx &&
    onChangeLandingPageContextIdx(index)
  return (
    <Grid>
      <GridItem xs={12}>
        <Header>
          <Image
            src={withPrefix(landingPage.theme.logo)}
            alt={landingPage.theme.name}
          />

          <section>
            {landingPage.landingPageContents.map(
              (landingPageContent, index) => (
                <ThemeButton
                  key={landingPageContent.id}
                  data-testid="language-switcher"
                  tertiary
                  color={landingPage.theme.primaryColor}
                  active={index === selectedLandingPageContentIdx}
                  onClick={() => handleLandingPageContextChange(index)}
                >
                  {landingPageContent.language.id}
                </ThemeButton>
              ),
            )}
          </section>
        </Header>
      </GridItem>
    </Grid>
  )
}
