import React from 'react'

import {
  Typography,
  Grid as ArmorGrid,
  GridItem,
  styled,
} from '@deliveryhero/armor'

import { MediaEditInfo } from '../../components/Atoms/MediaEditInfo'
import { VideoPlayer } from '../../components/Atoms/VideoPlayer/VideoPlayer.component'
import { prefixUrlIfRelative } from '../../utils/prefixUrlIfRelative'
import { BLOCK_TYPES } from '../../utils/renderer'

const Grid = ({ item, ...props }) =>
  item ? <GridItem {...props} /> : <ArmorGrid {...props} />

const ImageBlock = ({ children, alt = 'Image/Video Block', ...props }) => (
  <div {...props} style={{ ...(props.style || {}), position: 'relative' }}>
    <MediaEditInfo {...props} />
    <img {...props} alt={alt} src={prefixUrlIfRelative(children)} />
  </div>
)

const VideoBlock = ({ children, alt = 'Image/Video Block', ...props }) => (
  <VideoContainer {...props}>
    <MediaEditInfo {...props} />
    <VideoPlayer url={children} />
  </VideoContainer>
)

const ListContainer = styled.ul<{ type: string }>`
  list-style-type: ${props => (props.type === 'bullet' ? 'disc' : 'decimal')};
`

const ListItem = styled(Typography)`
  margin-bottom: 0 !important;
`

const ListComponent = ({ children, type, ...props }) => (
  <ListContainer {...props} type={type}>
    {children.split('\n').map((text: string) => (
      <ListItem tag="li" {...props} key={`${props['data-id']}-${text}`}>
        {text}
      </ListItem>
    ))}
  </ListContainer>
)

export const rendererMapping = {
  [BLOCK_TYPES.LAYOUT_BLOCK]: Grid,
  [BLOCK_TYPES.TEXT_BLOCK]: Typography,
  [BLOCK_TYPES.IMAGE_BLOCK]: ImageBlock,
  [BLOCK_TYPES.VIDEO_BLOCK]: VideoBlock,
  [BLOCK_TYPES.TEXT_LIST]: ListComponent,
}

const VideoContainer = styled.div`
  padding-bottom: 56%;
  position: relative;
  min-width: 222px;
`
