import React from 'react'
import styled from 'styled-components'

import { EditIcon } from '@deliveryhero/armor-icons'

const MediaEditContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  border: none !important;
  background-color: #fafafa;
  padding: 16px;
  border-radius: 0 !important;
  min-width: 100%;
  text-align: left;
  z-index: 100;
`

// Need this because the popover trigger doesn't work with armor icons
const Overlay = styled.div`
  height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: transparent;
  border: none !important;
`

export const MediaEditInfo = props => (
  <MediaEditContainer {...props} className="media-edit-label">
    <Overlay {...props} />
    <EditIcon large color="rgb(28,94,206)" />
  </MediaEditContainer>
)
