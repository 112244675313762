import axios from 'axios'
import queryString, { Stringifiable } from 'query-string'

import {
  CLOUDFLARE_ACCESS_LOGOUT_PATH,
  createDHHInterceptors,
} from '@deliveryhero/logistics-auth-interceptors'

import { toastError } from '../components/toasts/Error'
import { backofficeOnlyConfig } from '../config/global'
import { DodoFile, KeyedResponseData, NewDodoFile } from '../global/types'

const handleUnauthorizedRequests = () => {
  window.location.replace(CLOUDFLARE_ACCESS_LOGOUT_PATH)
}

// Do not include the interceptors in the compiled landing page
const initInterceptors = () => {
  if (
    typeof window !== 'undefined' &&
    typeof document !== 'undefined' &&
    document.location.hostname.includes('logisticsbackoffice.com')
  ) {
    const { clearTokens } = createDHHInterceptors(
      axios,
      `${backofficeOnlyConfig.iamLoginBaseUrl}`,
      handleUnauthorizedRequests,
    )

    return clearTokens
  }

  // eslint-disable-next-line
  return () => {}
}

export const clearTokens = initInterceptors()

const preProcessOptions = ({ params = {}, data = {}, ...options }) => ({
  params,
  data,
  paramsSerializer: (parameters: Record<string, Stringifiable>) =>
    queryString.stringify(parameters, { arrayFormat: 'bracket' }),
  withCredentials: true,
  ...options,
})

export async function makeRequest<T>(
  method: string,
  url: string,
  options: Record<string, unknown>,
): Promise<T> {
  const [_statusCode, data] = await makeRequestBase<T>(method, url, options)

  return data
}

/**
 * @returns [statusCode, data]
 */
export async function makeRequestBase<T>(
  method: string,
  url: string,
  options: Record<string, unknown>,
): Promise<[number, T]> {
  try {
    const response = await axios.request(
      preProcessOptions({ method, url, ...options }),
    )

    return [response.status, response.data]
  } catch (error) {
    if (error) {
      toastError(error.message)
    }

    const { data } = error.response

    throw new Error(
      data.message ??
        data.errors?.[0]?.message ??
        data.errors?.[0]?.detail ??
        error.message,
    )
  }
}

const requests = {
  fields: '/fields',
  landingPages: '/landing_pages',
  workflows: '/workflows',
  vehicles: '/vehicles',
  landingPageContents: '/landing_page_contents',
  seos: '/seos',
}

const makeResourceRequests = (
  resourceName,
  items,
): Promise<typeof makeRequestBase>[] => {
  const promises = []
  Object.keys(items).forEach(fieldId => {
    if (items[fieldId].restOperation === 'PATCH') {
      const formatedResource = Object.keys(items[fieldId]).reduce(
        (newObject, currentKey) => {
          // When an nested object has the property restRealKey then this is a FOREIGN key and will be changed when making the actual rest requests
          if (
            typeof items[fieldId][currentKey] === 'object' &&
            items[fieldId][currentKey].restRealKey
          ) {
            return {
              ...newObject,
              [items[fieldId][currentKey].restRealKey]:
                items[fieldId][currentKey].id,
            }
          }

          if (currentKey === 'restOperation') {
            return {
              ...newObject,
            }
          }

          return {
            ...newObject,
            [currentKey]: items[fieldId][currentKey],
          }
        },
        {},
      )
      promises.push(
        makeRequestBase(
          items[fieldId].restOperation,
          `${requests[resourceName]}/${fieldId}`,
          {
            baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
            data: formatedResource,
          },
        ),
      )
    }
    if (items[fieldId].restOperation === 'POST') {
      promises.push(
        makeRequestBase(
          items[fieldId].restOperation,
          `${requests[resourceName]}`,
          {
            baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
            data: { ...items[fieldId], restOperation: undefined },
          },
        ),
      )
    }
  })

  return promises
}

export function persistChangesInServer(changes: KeyedResponseData) {
  return Promise.all(
    Object.keys(changes)
      .map(resourceName =>
        makeResourceRequests(resourceName, changes[resourceName]),
      )
      .flat(),
  )
}

export async function uploadFile(file: NewDodoFile) {
  const formData = new FormData()
  Object.keys(file).forEach(key => formData.append(key, file[key]))

  return makeRequest<DodoFile>('post', 'files', {
    baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
    data: formData,
  })
}
